var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.idPopupSendEmail,
      "size": "xl",
      "centered": "",
      "header-bg-variant": "light-info",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "footer-class": "justify-content-between",
      "title-class": "h2 font-weight-bolder text-dark",
      "title": _vm.useWhenSignInvoice ? _vm.$t('invoice.popup.selectSendEmailOption') : _vm.$t('invoice.popup.sendEmailTitle'),
      "ok-title": _vm.useWhenSignInvoice ? _vm.$t('invoice.continue') : _vm.$t('send'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "hidden": function hidden($event) {
        return _vm.$emit('hidden');
      },
      "ok": _vm.onSeparateFlowSendEmailAndSignInvoice
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    staticClass: "pt-1",
    attrs: {
      "tag": "div"
    }
  }, [_c('b-form', [_vm.useWhenSignInvoice ? _c('div', [_c('b-form-checkbox', {
    model: {
      value: _vm.isAutoSendEmail,
      callback: function callback($$v) {
        _vm.isAutoSendEmail = $$v;
      },
      expression: "isAutoSendEmail"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.autoSendEmail')) + " ")])], 1) : _vm._e(), !_vm.useWhenSignInvoice || _vm.isAutoSendEmail ? _c('b-table', {
    staticStyle: {
      "max-height": "75vh",
      "margin": "0.75em 0",
      "padding": "0"
    },
    attrs: {
      "sticky-header": "",
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "table-class": "table-invoice-send-email border-bottom",
      "details-td-class": "bg-primary",
      "items": _vm.emailsData,
      "fields": _vm.tableColumns,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var label = _ref.label;
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.seColumns.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "table-colgroup",
      fn: function fn(_ref2) {
        var fields = _ref2.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: field.style
          });
        });
      }
    }, {
      key: "top-row",
      fn: function fn() {
        return [_c('td'), _c('td'), _c('td', {
          staticClass: "font-weight-bold text-info",
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.phSendEmail')) + " ")])];
      },
      proxy: true
    }, {
      key: "cell(orderNo)",
      fn: function fn(_ref3) {
        var index = _ref3.index;
        return [_c('div', {
          staticClass: "w-100 text-center"
        }, [_vm._v(" " + _vm._s(index + 1) + " ")])];
      }
    }, {
      key: "cell(receiver)",
      fn: function fn(_ref4) {
        var index = _ref4.index;
        return [_c('validation-provider', {
          attrs: {
            "tag": "div",
            "rules": "required",
            "name": _vm.$t('invoice.seColumns.receiver')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-input', {
                key: _vm.emailsData[index].id,
                attrs: {
                  "maxlength": "255",
                  "state": errors[0] ? false : null
                },
                model: {
                  value: _vm.emailsData[index].name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.emailsData[index], "name", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "emailsData[index].name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(recipientEmail)",
      fn: function fn(_ref6) {
        var index = _ref6.index;
        return [_c('validation-provider', {
          attrs: {
            "tag": "div",
            "rules": "required",
            "name": _vm.$t('email')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-input', {
                key: _vm.emailsData[index].id,
                attrs: {
                  "maxlength": "255",
                  "state": errors[0] ? false : null
                },
                model: {
                  value: _vm.emailsData[index].email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.emailsData[index], "email", typeof $$v === 'string' ? $$v.trim() : $$v);
                  },
                  expression: "emailsData[index].email"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(cc)",
      fn: function fn(_ref8) {
        var index = _ref8.index;
        return [_c('b-form-input', {
          key: _vm.emailsData[index].id,
          attrs: {
            "maxlength": "255"
          },
          model: {
            value: _vm.emailsData[index].cc,
            callback: function callback($$v) {
              _vm.$set(_vm.emailsData[index], "cc", typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "emailsData[index].cc"
          }
        })];
      }
    }, {
      key: "cell(bcc)",
      fn: function fn(_ref9) {
        var index = _ref9.index;
        return [_c('b-form-input', {
          key: _vm.emailsData[index].id,
          attrs: {
            "maxlength": "255"
          },
          model: {
            value: _vm.emailsData[index].bcc,
            callback: function callback($$v) {
              _vm.$set(_vm.emailsData[index], "bcc", typeof $$v === 'string' ? $$v.trim() : $$v);
            },
            expression: "emailsData[index].bcc"
          }
        })];
      }
    }, {
      key: "cell(invoiceNo)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          key: item.id,
          staticClass: "pl-50"
        }, [_vm._v(" " + _vm._s(item.invoiceNumber) + " ")])];
      }
    }, {
      key: "cell(formSerial)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          key: item.id
        }, [_vm._v(" " + _vm._s(item.formSerial) + " ")])];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          key: item.id
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPayment)) + " ")])];
      }
    }, {
      key: "cell(emailStatus)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          key: item.id,
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.emailStatus ? _vm.$t('invoice.emailStatusOptions.sent') : _vm.$t('invoice.emailStatusOptions.notSent')) + " ")])];
      }
    }, {
      key: "cell(preview)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "w-100 text-center cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.$emit('showPreview', item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1)];
      }
    }], null, true)
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }