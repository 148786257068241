<template>
  <b-modal
    :id="idPopupSendEmail"
    size="xl"
    centered
    header-bg-variant="light-info"
    no-close-on-backdrop
    no-close-on-esc
    footer-class="justify-content-between"
    title-class="h2 font-weight-bolder text-dark"
    :title="
      useWhenSignInvoice
        ? $t('invoice.popup.selectSendEmailOption')
        : $t('invoice.popup.sendEmailTitle')
    "
    :ok-title="useWhenSignInvoice ? $t('invoice.continue') : $t('send')"
    :cancel-title="$t('cancel')"
    @hidden="$emit('hidden')"
    @ok="onSeparateFlowSendEmailAndSignInvoice"
  >
    <validation-observer
      ref="refFormObserver"
      tag="div"
      class="pt-1"
    >
      <b-form>
        <div v-if="useWhenSignInvoice">
          <b-form-checkbox
            v-model="isAutoSendEmail"
          >
            {{ $t('invoice.autoSendEmail') }}
          </b-form-checkbox>
        </div>

        <b-table
          v-if="!useWhenSignInvoice || isAutoSendEmail"
          style="max-height: 75vh; margin: 0.75em 0; padding: 0;"
          sticky-header
          responsive
          show-empty
          primary-key="id"
          table-class="table-invoice-send-email border-bottom"
          details-td-class="bg-primary"
          :items="emailsData"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
        >
          <!-- ANCHOR Table Header -->
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="{ label }"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`invoice.seColumns.${label}`) }}
            </span>
          </template>

          <!-- ANCHOR Style for column(s)) -->
          <template #table-colgroup="{ fields }">
            <col
              v-for="field in fields"
              :key="field.key"
              :style="field.style"
            >
          </template>

          <!-- ANCHOR Table Top Row -->
          <template #top-row>
            <td />
            <td />
            <td
              colspan="8"
              class="font-weight-bold text-info"
            >
              {{ $t('invoice.phSendEmail') }}
            </td>
          </template>

          <!-- ANCHOR Column orderNo -->
          <template #cell(orderNo)="{ index }">
            <div
              class="w-100 text-center"
            >
              {{ index + 1 }}
            </div>
          </template>

          <!-- ANCHOR Column receiver -->
          <template #cell(receiver)="{ index }">
            <validation-provider
              #default="{ errors }"
              tag="div"
              rules="required"
              :name="$t('invoice.seColumns.receiver')"
            >
              <b-form-input
                :key="emailsData[index].id"
                v-model.trim="emailsData[index].name"
                maxlength="255"
                :state="errors[0] ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </template>

          <!-- ANCHOR Column recipientEmail -->
          <template #cell(recipientEmail)="{ index }">
            <validation-provider
              #default="{ errors }"
              tag="div"
              rules="required"
              :name="$t('email')"
            >
              <b-form-input
                :key="emailsData[index].id"
                v-model.trim="emailsData[index].email"
                maxlength="255"
                :state="errors[0] ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </template>

          <!-- ANCHOR Column CC -->
          <template #cell(cc)="{ index }">
            <b-form-input
              :key="emailsData[index].id"
              v-model.trim="emailsData[index].cc"
              maxlength="255"
            />
          </template>

          <!-- ANCHOR Column BCC -->
          <template #cell(bcc)="{ index }">
            <b-form-input
              :key="emailsData[index].id"
              v-model.trim="emailsData[index].bcc"
              maxlength="255"
            />
          </template>

          <!-- ANCHOR Column invoiceNo -->
          <template #cell(invoiceNo)="{ item }">
            <div
              :key="item.id"
              class="pl-50"
            >
              {{ item.invoiceNumber }}
            </div>
          </template>

          <!-- ANCHOR Column form/serial -->
          <template #cell(formSerial)="{ item }">
            <div
              :key="item.id"
            >
              {{ item.formSerial }}
            </div>
          </template>

          <!-- ANCHOR Column totalPayment -->
          <template #cell(totalPayment)="{ item }">
            <div
              :key="item.id"
            >
              {{ formatCurrency(item.totalPayment) }}
            </div>
          </template>

          <!-- ANCHOR Column emailStatus -->
          <template #cell(emailStatus)="{ item }">
            <div
              :key="item.id"
              class="text-nowrap"
            >
              {{ item.emailStatus
                ? $t('invoice.emailStatusOptions.sent')
                : $t('invoice.emailStatusOptions.notSent')
              }}
            </div>
          </template>

          <!-- ANCHOR Column preview -->
          <template #cell(preview)="{ item }">
            <div
              class="w-100 text-center cursor-pointer"
              @click="$emit('showPreview', item.id)"
            >
              <feather-icon icon="EyeIcon" />
            </div>
          </template>
        </b-table>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BModal,
  BTable,
  BForm,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'

import {
  INVOICE_SEND_EMAIL_TABLE_COLUMNS as tableColumns,
} from '@/constants/invoice'

import {
  useShowToast,
  formatCurrency,
} from '../useInvoice'
import {
  hidePopupInvoiceSendEmail,
  createEmailsData,
  onFlowSignInvoiceWithSendEmailOption,
  onFlowSendEmail,
  onSeparateFlowSendEmailAndSignInvoice,
} from './useInvoiceSendEmail'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BTable,
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
  },

  props: {
    idPopupSendEmail: {
      type: String,
      default: 'id-invoice-popup-send-email',
    },
    invoices: {
      type: Array,
      default: () => [],
    },
    useWhenSignInvoice: {
      type: Boolean,
      default: false,
    },
    autoSendEmail: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    invoiceIds() {
      return this.invoices.map(item => item.id)
    },
  },

  watch: {
    invoiceIds: {
      deep: true,
      immediate: true,
      handler() {
        this.createEmailsData(this.invoices)
      },
    },

    isAutoSendEmail(val) {
      this.$emit('update:autoSendEmail', val)
    },
  },

  setup(props) {
    const isAutoSendEmail = ref(props.autoSendEmail)
    const emailsData = ref([])

    const showToast = useShowToast()

    function showToastSuccess(message) {
      showToast(message, { success: true })
    }

    return {
      // from imports
      tableColumns,
      formatCurrency,
      hidePopupInvoiceSendEmail,
      createEmailsData,
      onFlowSignInvoiceWithSendEmailOption,
      onFlowSendEmail,
      onSeparateFlowSendEmailAndSignInvoice,

      isAutoSendEmail,
      emailsData,

      showToastSuccess,
    }
  },
}
</script>

<style lang="scss">
#id-invoice-popup-send-email {
  .modal-dialog {
    max-width: 1300px;
    margin: 0 auto;
  }
}
.table-invoice-send-email {
  th {
    text-transform: capitalize !important;
    padding: 8px 4px !important;
  }
  td {
    padding: 8px 4px !important;
  }
}
</style>
